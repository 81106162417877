/** @jsx jsx */
import { jsx } from "theme-ui"
import { useState, useEffect } from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"

const SalaryBenchmarking = () => {
  // State for job details
  const [jobDetails, setJobDetails] = useState({
    industry: "technology",
    jobFunction: "software_development",
    experienceLevel: "mid_level",
    companySize: "medium",
    location: "us_west",
  })

  // State for compensation components
  const [compensationDetails, setCompensationDetails] = useState({
    baseSalary: "",
    bonus: "",
    equity: "",
    benefits: "",
  })

  // Industry data
  const industries = {
    technology: "Technology",
    healthcare: "Healthcare",
    finance: "Finance",
    retail: "Retail",
    manufacturing: "Manufacturing",
    consulting: "Consulting",
  }

  // Job functions
  const jobFunctions = {
    software_development: "Software Development",
    data_science: "Data Science",
    product_management: "Product Management",
    marketing: "Marketing",
    sales: "Sales",
    hr: "Human Resources",
    finance_accounting: "Finance & Accounting",
    operations: "Operations",
  }

  // Experience levels
  const experienceLevels = {
    entry_level: "Entry Level (0-2 years)",
    mid_level: "Mid Level (3-5 years)",
    senior_level: "Senior Level (6-10 years)",
    expert_level: "Expert Level (10+ years)",
    executive: "Executive",
  }

  // Company sizes
  const companySizes = {
    startup: "Startup (1-50)",
    small: "Small (51-200)",
    medium: "Medium (201-1000)",
    large: "Large (1001-5000)",
    enterprise: "Enterprise (5000+)",
  }

  // Locations
  const locations = {
    us_west: "US West Coast",
    us_east: "US East Coast",
    us_midwest: "US Midwest",
    us_south: "US South",
    europe: "Europe",
    asia_pacific: "Asia Pacific",
  }

  // Benchmark data (sample ranges in USD)
  const benchmarkData = {
    technology: {
      software_development: {
        entry_level: {
          small: { min: 70000, max: 90000, median: 80000 },
          medium: { min: 85000, max: 105000, median: 95000 },
          large: { min: 95000, max: 120000, median: 110000 },
        },
        mid_level: {
          small: { min: 100000, max: 130000, median: 115000 },
          medium: { min: 120000, max: 150000, median: 135000 },
          large: { min: 140000, max: 180000, median: 160000 },
        },
        senior_level: {
          small: { min: 130000, max: 170000, median: 150000 },
          medium: { min: 150000, max: 200000, median: 175000 },
          large: { min: 180000, max: 250000, median: 215000 },
        },
      },
      // Add more job functions...
    },
    // Add more industries...
  }

  // Location multipliers
  const locationMultipliers = {
    us_west: 1.2,
    us_east: 1.15,
    us_midwest: 0.9,
    us_south: 0.85,
    europe: 0.95,
    asia_pacific: 0.8,
  }

  // Calculate benchmark salary range
  const calculateBenchmark = () => {
    const baseRange = benchmarkData?.technology?.software_development?.mid_level?.medium || {
      min: 120000,
      max: 150000,
      median: 135000,
    }

    const multiplier = locationMultipliers[jobDetails.location]

    return {
      min: Math.round(baseRange.min * multiplier),
      max: Math.round(baseRange.max * multiplier),
      median: Math.round(baseRange.median * multiplier),
    }
  }

  const benchmarkRange = calculateBenchmark()

  const handleJobDetailsChange = (field, value) => {
    setJobDetails(prev => ({
      ...prev,
      [field]: value
    }))
  }

  const handleCompensationChange = (field, value) => {
    setCompensationDetails(prev => ({
      ...prev,
      [field]: value
    }))
  }

  return (
    <Layout className="salary-benchmarking-page">
      <SEO 
        title="Salary Benchmarking Tool | Compensation Comparison Calculator" 
        description="Compare compensation packages against industry standards. Customize by industry, location, experience level, and company size."
      />
      <div className="wrapper" sx={{ maxWidth: '1200px', margin: '0 auto', padding: '20px' }}>
        <h1 sx={{ fontSize: ['1.75rem', '2rem', '2.25rem'], mb: 4 }}>
          Salary Benchmarking Tool
        </h1>

        {/* Description Section */}
        <div sx={{ 
          bg: 'white', 
          p: 4, 
          borderRadius: '8px',
          boxShadow: '0 2px 10px rgba(0,0,0,0.1)',
          mb: 4
        }}>
          <h2 sx={{ fontSize: '1.3rem', mb: 3, color: 'primary' }}>About This Tool</h2>
          <p sx={{ fontSize: '1rem', lineHeight: '1.6', mb: 3 }}>
            Make informed compensation decisions with our Salary Benchmarking Tool. Compare salaries against 
            industry standards while considering key factors such as location, experience level, and company size.
          </p>
          <ul sx={{ 
            listStyle: 'disc',
            pl: 4,
            mb: 3,
            '& li': { 
              mb: 2,
              fontSize: '1rem',
              lineHeight: '1.5'
            }
          }}>
            <li>Industry-specific compensation data</li>
            <li>Regional salary adjustments</li>
            <li>Company size considerations</li>
            <li>Experience-based scaling</li>
          </ul>
          <p sx={{ fontSize: '1rem', lineHeight: '1.6', fontStyle: 'italic' }}>
            Data updated quarterly based on market research and industry surveys.
          </p>
        </div>

        {/* Calculator Form */}
        <div sx={{ 
          display: 'grid', 
          gridTemplateColumns: ['1fr', '1fr', '1fr 1fr'], 
          gap: 4,
          bg: 'white',
          p: 4,
          borderRadius: '8px',
          boxShadow: '0 2px 10px rgba(0,0,0,0.1)'
        }}>
          {/* Job Details Section */}
          <div>
            <h2 sx={{ fontSize: '1.5rem', mb: 3, color: 'primary' }}>Job Details</h2>
            <div sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
              <div>
                <label htmlFor="industry" sx={{ display: 'block', mb: 2 }}>
                  Industry
                  <span sx={{ display: 'block', fontSize: '0.875rem', color: 'gray.6' }}>
                    Select the primary industry
                  </span>
                </label>
                <select
                  id="industry"
                  value={jobDetails.industry}
                  onChange={(e) => handleJobDetailsChange('industry', e.target.value)}
                  sx={{
                    width: '100%',
                    p: 2,
                    border: '1px solid',
                    borderColor: 'gray.3',
                    borderRadius: '4px'
                  }}
                >
                  {Object.entries(industries).map(([key, value]) => (
                    <option key={key} value={key}>{value}</option>
                  ))}
                </select>
              </div>

              <div>
                <label htmlFor="jobFunction" sx={{ display: 'block', mb: 2 }}>
                  Job Function
                  <span sx={{ display: 'block', fontSize: '0.875rem', color: 'gray.6' }}>
                    Select the role category
                  </span>
                </label>
                <select
                  id="jobFunction"
                  value={jobDetails.jobFunction}
                  onChange={(e) => handleJobDetailsChange('jobFunction', e.target.value)}
                  sx={{
                    width: '100%',
                    p: 2,
                    border: '1px solid',
                    borderColor: 'gray.3',
                    borderRadius: '4px'
                  }}
                >
                  {Object.entries(jobFunctions).map(([key, value]) => (
                    <option key={key} value={key}>{value}</option>
                  ))}
                </select>
              </div>

              <div>
                <label htmlFor="experienceLevel" sx={{ display: 'block', mb: 2 }}>
                  Experience Level
                  <span sx={{ display: 'block', fontSize: '0.875rem', color: 'gray.6' }}>
                    Select years of experience
                  </span>
                </label>
                <select
                  id="experienceLevel"
                  value={jobDetails.experienceLevel}
                  onChange={(e) => handleJobDetailsChange('experienceLevel', e.target.value)}
                  sx={{
                    width: '100%',
                    p: 2,
                    border: '1px solid',
                    borderColor: 'gray.3',
                    borderRadius: '4px'
                  }}
                >
                  {Object.entries(experienceLevels).map(([key, value]) => (
                    <option key={key} value={key}>{value}</option>
                  ))}
                </select>
              </div>

              <div>
                <label htmlFor="companySize" sx={{ display: 'block', mb: 2 }}>
                  Company Size
                  <span sx={{ display: 'block', fontSize: '0.875rem', color: 'gray.6' }}>
                    Select company size by employees
                  </span>
                </label>
                <select
                  id="companySize"
                  value={jobDetails.companySize}
                  onChange={(e) => handleJobDetailsChange('companySize', e.target.value)}
                  sx={{
                    width: '100%',
                    p: 2,
                    border: '1px solid',
                    borderColor: 'gray.3',
                    borderRadius: '4px'
                  }}
                >
                  {Object.entries(companySizes).map(([key, value]) => (
                    <option key={key} value={key}>{value}</option>
                  ))}
                </select>
              </div>

              <div>
                <label htmlFor="location" sx={{ display: 'block', mb: 2 }}>
                  Location
                  <span sx={{ display: 'block', fontSize: '0.875rem', color: 'gray.6' }}>
                    Select primary work location
                  </span>
                </label>
                <select
                  id="location"
                  value={jobDetails.location}
                  onChange={(e) => handleJobDetailsChange('location', e.target.value)}
                  sx={{
                    width: '100%',
                    p: 2,
                    border: '1px solid',
                    borderColor: 'gray.3',
                    borderRadius: '4px'
                  }}
                >
                  {Object.entries(locations).map(([key, value]) => (
                    <option key={key} value={key}>{value}</option>
                  ))}
                </select>
              </div>
            </div>
          </div>

          {/* Compensation Details Section */}
          <div>
            <h2 sx={{ fontSize: '1.5rem', mb: 3, color: 'primary' }}>Compensation Details</h2>
            <div sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
              <div>
                <label htmlFor="baseSalary" sx={{ display: 'block', mb: 2 }}>
                  Base Salary (USD)
                  <span sx={{ display: 'block', fontSize: '0.875rem', color: 'gray.6' }}>
                    Annual base compensation
                  </span>
                </label>
                <input
                  type="number"
                  id="baseSalary"
                  value={compensationDetails.baseSalary}
                  onChange={(e) => handleCompensationChange('baseSalary', e.target.value)}
                  placeholder="e.g., 100000"
                  sx={{
                    width: '100%',
                    p: 2,
                    border: '1px solid',
                    borderColor: 'gray.3',
                    borderRadius: '4px'
                  }}
                />
              </div>

              <div>
                <label htmlFor="bonus" sx={{ display: 'block', mb: 2 }}>
                  Annual Bonus (USD)
                  <span sx={{ display: 'block', fontSize: '0.875rem', color: 'gray.6' }}>
                    Expected annual bonus
                  </span>
                </label>
                <input
                  type="number"
                  id="bonus"
                  value={compensationDetails.bonus}
                  onChange={(e) => handleCompensationChange('bonus', e.target.value)}
                  placeholder="e.g., 15000"
                  sx={{
                    width: '100%',
                    p: 2,
                    border: '1px solid',
                    borderColor: 'gray.3',
                    borderRadius: '4px'
                  }}
                />
              </div>

              <div>
                <label htmlFor="equity" sx={{ display: 'block', mb: 2 }}>
                  Annual Equity (USD)
                  <span sx={{ display: 'block', fontSize: '0.875rem', color: 'gray.6' }}>
                    Estimated value of equity compensation
                  </span>
                </label>
                <input
                  type="number"
                  id="equity"
                  value={compensationDetails.equity}
                  onChange={(e) => handleCompensationChange('equity', e.target.value)}
                  placeholder="e.g., 20000"
                  sx={{
                    width: '100%',
                    p: 2,
                    border: '1px solid',
                    borderColor: 'gray.3',
                    borderRadius: '4px'
                  }}
                />
              </div>

              <div>
                <label htmlFor="benefits" sx={{ display: 'block', mb: 2 }}>
                  Benefits Value (USD)
                  <span sx={{ display: 'block', fontSize: '0.875rem', color: 'gray.6' }}>
                    Estimated annual value of benefits
                  </span>
                </label>
                <input
                  type="number"
                  id="benefits"
                  value={compensationDetails.benefits}
                  onChange={(e) => handleCompensationChange('benefits', e.target.value)}
                  placeholder="e.g., 25000"
                  sx={{
                    width: '100%',
                    p: 2,
                    border: '1px solid',
                    borderColor: 'gray.3',
                    borderRadius: '4px'
                  }}
                />
              </div>
            </div>
          </div>
        </div>

        {/* Results Section */}
        <div sx={{ 
          mt: 4,
          p: 4,
          bg: 'white',
          borderRadius: '8px',
          boxShadow: '0 2px 10px rgba(0,0,0,0.1)'
        }}>
          <h2 sx={{ fontSize: '1.5rem', mb: 3, color: 'primary' }}>Benchmark Results</h2>
          <div sx={{ display: 'grid', gridTemplateColumns: ['1fr', '1fr 1fr 1fr'], gap: 4 }}>
            <div>
              <h3 sx={{ fontSize: '1.1rem', color: 'gray.7' }}>25th Percentile</h3>
              <p sx={{ fontSize: '1.5rem', fontWeight: 'bold' }}>
                ${benchmarkRange.min.toLocaleString()}
              </p>
            </div>
            <div>
              <h3 sx={{ fontSize: '1.1rem', color: 'gray.7' }}>Median</h3>
              <p sx={{ fontSize: '1.5rem', fontWeight: 'bold', color: 'primary' }}>
                ${benchmarkRange.median.toLocaleString()}
              </p>
            </div>
            <div>
              <h3 sx={{ fontSize: '1.1rem', color: 'gray.7' }}>75th Percentile</h3>
              <p sx={{ fontSize: '1.5rem', fontWeight: 'bold' }}>
                ${benchmarkRange.max.toLocaleString()}
              </p>
            </div>
          </div>
        </div>

        {/* Market Insights Section */}
        <div sx={{ 
          mt: 4,
          p: 4,
          bg: 'white',
          borderRadius: '8px',
          boxShadow: '0 2px 10px rgba(0,0,0,0.1)'
        }}>
          <h2 sx={{ fontSize: '1.5rem', mb: 3, color: 'primary' }}>Market Insights</h2>
          <div sx={{ display: 'grid', gridTemplateColumns: ['1fr', '1fr 1fr'], gap: 4 }}>
            <div>
              <h3 sx={{ fontSize: '1.2rem', fontWeight: 'bold', mb: 2 }}>Industry Trends</h3>
              <ul sx={{ 
                listStyle: 'none',
                '& li': { 
                  mb: 2,
                  fontSize: '1rem',
                  lineHeight: '1.5',
                  display: 'flex',
                  justifyContent: 'space-between'
                }
              }}>
                <li>Annual Growth Rate <span>+3.5%</span></li>
                <li>Equity Compensation <span>15-25%</span></li>
                <li>Benefits Value <span>20-30%</span></li>
                <li>Variable Pay <span>10-20%</span></li>
              </ul>
            </div>
            <div>
              <h3 sx={{ fontSize: '1.2rem', fontWeight: 'bold', mb: 2 }}>Regional Variations</h3>
              <ul sx={{ 
                listStyle: 'none',
                '& li': { 
                  mb: 2,
                  fontSize: '1rem',
                  lineHeight: '1.5',
                  display: 'flex',
                  justifyContent: 'space-between'
                }
              }}>
                <li>US West Coast <span>+20%</span></li>
                <li>US East Coast <span>+15%</span></li>
                <li>US Midwest <span>-10%</span></li>
                <li>US South <span>-15%</span></li>
              </ul>
            </div>
          </div>
        </div>

        {/* FAQ Section */}
        <div sx={{ 
          mt: 4,
          p: 4,
          bg: 'white',
          borderRadius: '8px',
          boxShadow: '0 2px 10px rgba(0,0,0,0.1)'
        }}>
          <h2 sx={{ fontSize: '1.5rem', mb: 4, color: 'primary' }}>Frequently Asked Questions</h2>
          
          <div sx={{ display: 'flex', flexDirection: 'column', gap: 4 }}>
            <div>
              <h3 sx={{ fontSize: '1.2rem', fontWeight: 'bold', mb: 2 }}>
                How often is the salary data updated?
              </h3>
              <p sx={{ fontSize: '1rem', lineHeight: '1.6' }}>
                Our salary data is updated quarterly based on market research, industry surveys, and real-time 
                compensation data from various sources. This ensures you have access to the most current market rates.
              </p>
            </div>

            <div>
              <h3 sx={{ fontSize: '1.2rem', fontWeight: 'bold', mb: 2 }}>
                What factors influence salary benchmarks?
              </h3>
              <p sx={{ fontSize: '1rem', lineHeight: '1.6' }}>
                Key factors include industry type, job function, experience level, company size, and location. 
                Additional factors like company funding stage, market conditions, and specialized skills can also 
                impact compensation.
              </p>
            </div>

            <div>
              <h3 sx={{ fontSize: '1.2rem', fontWeight: 'bold', mb: 2 }}>
                How should I use these benchmarks?
              </h3>
              <p sx={{ fontSize: '1rem', lineHeight: '1.6' }}>
                Use these benchmarks as a starting point for compensation planning. Consider your company's specific 
                circumstances, budget constraints, and total compensation strategy. Remember to factor in your 
                organization's competitive position in the market.
              </p>
            </div>

            <div>
              <h3 sx={{ fontSize: '1.2rem', fontWeight: 'bold', mb: 2 }}>
                What's included in total compensation?
              </h3>
              <p sx={{ fontSize: '1rem', lineHeight: '1.6' }}>
                Total compensation typically includes base salary, bonuses, equity compensation, and benefits. 
                Some companies also offer additional perks like professional development budgets, wellness programs, 
                or transportation allowances.
              </p>
            </div>
          </div>
        </div>

        {/* Latest Research Section */}
        <div sx={{ 
          mt: 4,
          p: 4,
          bg: 'white',
          borderRadius: '8px',
          boxShadow: '0 2px 10px rgba(0,0,0,0.1)'
        }}>
          <h2 sx={{ fontSize: '1.5rem', mb: 3, color: 'primary' }}>Latest Compensation Trends</h2>
          <div sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
            <p sx={{ fontSize: '1rem', lineHeight: '1.6' }}>
              Recent compensation trends show:
            </p>
            <ul sx={{ 
              listStyle: 'disc',
              pl: 4,
              '& li': { 
                mb: 2,
                fontSize: '1rem',
                lineHeight: '1.5'
              }
            }}>
              <li>Increased focus on equity compensation</li>
              <li>Rising importance of flexible benefits</li>
              <li>Growth in remote work adjustments</li>
              <li>Emphasis on performance-based bonuses</li>
            </ul>
            <div sx={{ 
              mt: 3,
              p: 3,
              bg: 'gray.1',
              borderRadius: '4px',
              borderLeft: '4px solid',
              borderLeftColor: 'primary'
            }}>
              <p sx={{ fontSize: '1rem', lineHeight: '1.6', fontStyle: 'italic' }}>
                "Companies are increasingly adopting transparent compensation practices and structured pay bands. 
                This trend, combined with the rise of remote work, is reshaping how organizations approach salary 
                benchmarking and total compensation planning."
                <br />
                - Maria Rodriguez, Chief Compensation Officer, Global HR Advisory
              </p>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default SalaryBenchmarking 